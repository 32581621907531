import React from 'react';
import AppBar from '../../molecules/appBar';
import Drawer from '../../molecules/drawer';

const AppBarWithDrawer = ({ handleMenuClick, appBarPosition, isLandingPage, handleCloseMenu, openDrawer }) => {
  return (
    <>
      <AppBar handleMenuClick={handleMenuClick} position={appBarPosition} isLandingPage={isLandingPage} />
      <Drawer handleCloseMenu={handleCloseMenu} openDrawer={openDrawer} />
    </>
  );
};

export default AppBarWithDrawer;
