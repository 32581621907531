import styled from 'styled-components';
import { Colors } from '../../config';

export const Container = styled.div`
  display: flex;

  background-color: ${Colors.PRIMARY};
  height: 100vh;

  flex-direction: column;

  padding: 16px;

  img {
    margin-right: auto;
    height: 48px;
    margin-bottom: 16px;
  }

  @media (min-width: 800px) {
    justify-content: center;
    padding: 0 25%;
  }

  p {
    font-size: 20px;
  }

  form {
    input {
      border: 2px solid white;
      background: transparent;
      border-radius: 7px;
      height: 48px;
      padding: 12px;

      font-size: 17px;
      color: #f1f1f1;

      margin: 24px 0 4px;

      width: 100%;

      ::placeholder {
        color: #f1f1f1;
        opacity: 0.8;
      }
    }

    button {
      height: 54px;
      border-radius: 30px;
      background: #f1f1f1;
      border-color: #f1f1f1;

      border-style: solid;

      width: 100%;

      margin-top: 32px;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1.25px;
      font-weight: 600;
      color: ${Colors.PRIMARY};

      :disabled {
        border-color: transparent;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    p {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;
