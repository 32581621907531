import styled from 'styled-components';
import { Colors } from '../../../config';

export const FooterWrapper = styled.div`
  display: 'flex';
  justify-content: 'center';
  text-align: center;
  padding: 32px 0;

  font-size: 0.75rem;
  font-family: 'Montserrat';

  color: ${Colors.GRAY};
`;
