import React, { useState } from 'react';
import { Collapse, List } from '@material-ui/core';

import AppBarWithDrawer from '../../components/organisms/appBarWithDrawer';
import chevronRight from '../../assets/chevron-right.svg';
import {
  PageWrapper,
  ContentWrapper,
  Title,
  QuestionContainer,
  Image,
  Description,
  ListItem,
  TitleWrapper,
  PageTitle,
} from './styles';
import { questions } from './questions';
import { Footer } from '../../components/organisms/footer';

export const Faq = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const [isOpenCollapse, setIsOpenCollapse] = useState(null);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleOpen = (clickedIndex, event) => {
    const targetTagName = event.target.tagName.toLowerCase();

    // Ignore the click if the target is an anchor tag (link) or if text is being selected
    if (targetTagName === 'a' || window.getSelection().toString()) {
      return;
    }

    if (isOpenCollapse === clickedIndex) {
      setIsOpenCollapse(null);
    } else {
      setIsOpenCollapse(clickedIndex);
    }
  };

  return (
    <PageWrapper>
      <AppBarWithDrawer
        handleMenuClick={openMenu}
        handleCloseMenu={closeMenu}
        openDrawer={menuOpen}
        appBarPosition='fixed'
      />
      <ContentWrapper>
        <PageTitle>Perguntas frequentes</PageTitle>
        <List>
          {questions.map((elm, index) => {
            const isOpen = isOpenCollapse === index;
            return (
              <QuestionContainer key={elm.ID} onClick={event => handleOpen(index, event)}>
                <ListItem id='listItem'>
                  <TitleWrapper isOpen={isOpen}>
                    <Title isOpen={isOpen}>{elm.title}</Title>
                    <Image src={chevronRight} alt='chevron-right' isOpen={isOpen} />
                  </TitleWrapper>
                </ListItem>
                <Collapse in={isOpenCollapse === index} timeout='auto' unmountOnExit>
                  <Description dangerouslySetInnerHTML={{ __html: elm.description }} />
                </Collapse>
              </QuestionContainer>
            );
          })}
        </List>
        <Footer />
      </ContentWrapper>
    </PageWrapper>
  );
};
