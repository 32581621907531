import styled from 'styled-components';
import { Colors } from '../../config';

export const PageWrapper = styled.div`
  height: auto;
  background-color: ${Colors.BLACK};
  min-height: 100vh;
  padding-top: 85px;
`;

export const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;

  color: ${Colors.PRIMARY};
  margin: 32px 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;

  background-color: ${Colors.BLACK};
`;

export const QuestionContainer = styled.div`
  display: block;
  max-width: 732px;
  margin: 0 auto 14px;
  padding: 24px;
  border-radius: 8px;
  background-color: ${Colors.GRAY_DARK};
  box-sizing: border-box;
  cursor: pointer;

  @media (max-width: 767px) {
    width: calc(100% - 32px);
  }

  @media (min-width: 768px) {
    width: 732px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h4`
  padding: 0;
  margin: 0;

  line-height: 1.2;
  font-size: 1.125rem;
  font-weight: 600;
`;

export const Image = styled.img`
  width: 16px;
  height: 16px;

  color: ${Colors.PRIMARY};

  transform: ${({ isOpen }) => (isOpen ? 'rotate(-90deg)' : 'rotate(90deg)')};
  transition: transform 0.2s ease-in-out;
`;

export const Description = styled.body`
  white-space: pre-wrap;
  word-break: break-word;

  font-size: 1rem;
  line-height: 1.4;

  padding-top: 18px;
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 100%;

  padding: 0;
  margin: 0;
`;
