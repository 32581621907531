import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from './styles/global';

import Routes from './routes';

const THEME = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={THEME}>
        <Routes />
      </ThemeProvider>

      <GlobalStyle />
    </BrowserRouter>
  );
}

export default App;
