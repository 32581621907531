import { useState } from 'react';

export const useClipboard = text => {
  const [hasCopied, setHasCopied] = useState(false);

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        setHasCopied(true);
        setTimeout(() => {
          setHasCopied(false);
        }, 3000);
      });
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      setHasCopied(true);
      setTimeout(() => {
        setHasCopied(false);
      }, 3000);
    }
  };

  return [hasCopied, handleCopy];
};
