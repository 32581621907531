import React from 'react';
import { ArrowBack } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ButtonStyled } from './styles';

export const Ubutton = props => {
  return getButton(ButtonStyled, props);
};

const getButton = (Component, props) => {
  return (
    <Component
      {...props}
      startIcon={props.utype === 'goBack' ? <ArrowBack /> : null}
      variant='contained'
      bold={!!props.bold}
      disabled={props.disabled ? props.disabled : false}
      utype={props.utype}
      loading={props.loading}
    >
      {props.loading ? (
        <CircularProgress size='16px' color={props.utype === 'registerWelcome' ? 'secondary' : 'primary'} />
      ) : (
        props.text
      )}
      {props.children}
    </Component>
  );
};
