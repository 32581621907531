import React, { useState } from 'react';
import AppBarWithDrawer from '../../components/organisms/appBarWithDrawer';
import GetAppDialog from '../../components/molecules/getAppDialog';
import { Footer } from '../../components/organisms/footer';
import { Container, Content, AppSpoilerImage, ImageWrapper, Button } from './styles';
import { Images } from '../../config';

export const AboutUs = () => {
  const [getAppDialogOpen, setGetAppDialogOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Container>
      <AppBarWithDrawer
        handleMenuClick={() => setMenuOpen(true)}
        handleCloseMenu={() => setMenuOpen(false)}
        openDrawer={menuOpen}
        appBarPosition='fixed'
      />
      <GetAppDialog open={getAppDialogOpen} handleClose={() => setGetAppDialogOpen(false)} />

      <Content>
        <h1>Sobre nós</h1>
        <h2>Nossa missão</h2>
        <p>Em um mundo altamente conectado, formar a rede de maior valor para integração universitária.</p>

        <h2>Como</h2>
        <p>
          Com um aplicativo de integração exclusivo para universitários, no qual eles podem descobrir e interagir com
          outros universitários de forma única, segura e fácil.
        </p>
        <h2>Diferencial</h2>
        <p>
          Apenas universitários convidados e verificados conseguem acesso à Umatch. Com isso, você consegue encontrar
          pessoais reais e que vivem a mesma fase de vida que você.
        </p>
        <h2>Diversidade</h2>
        <p>
          Não importa sua forma de amor, o que importa é saber amar, essa é a essência da Umatch. Não toleramos
          preconceitos
        </p>
        <ImageWrapper>
          <AppSpoilerImage src={Images.APP_SPOILER} alt='Umatch App' title='Umatch App' />
        </ImageWrapper>
        <h3>Tá esperando o que?</h3>

        <Button onClick={() => setGetAppDialogOpen(true)}>Baixar o App</Button>
      </Content>
      <Footer />
    </Container>
  );
};
