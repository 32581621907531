import styled from 'styled-components';
import { Colors } from '../../config';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.BLACK};

  padding: 64px;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 48px 0 24px;
    color: ${Colors.PRIMARY};
    text-align: center;
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 24px 0 16px;
    color: ${Colors.PRIMARY};
  }

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 24px 0 16px;
    color: ${Colors.PRIMARY};
    text-align: center;
  }

  p {
    font-size: 0.875rem;

    & + p {
      margin-top: 16px;
    }

    a {
      color: ${Colors.PRIMARY};
      text-decoration: none;
      transition: color 0.2s;
    }
  }

  ul {
    margin: 16px 0;
    li {
      font-size: 0.875rem;
      margin-left: 16px;
      & + li {
        margin-top: 16px;
      }
    }
  }

  strong {
    font-weight: 600;
    font-size: 0.875rem;
    margin-top: 16px;
  }
`;

export const AppSpoilerImage = styled.img`
  width: 300px;
  max-width: 100%;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 100%;

  margin-top: 32px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75ch;
  margin: 0 auto;
`;

export const Button = styled.button`
  width: 200px;
  max-width: 80%;

  padding: 16px;
  margin-top: 8px;
  align-self: center;

  border: none;
  border-radius: 32px;

  color: ${Colors.WHITE};
  background-color: ${Colors.PRIMARY};

  font-weight: 500;
  text-transform: uppercase;
`;
