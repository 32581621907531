import React from 'react';
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Images } from '../../../config';

const GetAppDialog = ({ open, handleClose }) => {
  return (
    <Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby='responsive-dialog-title'>
        <DialogTitle
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
          id='responsive-dialog-title'
        >
          Baixe a Umatch
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <a href={process.env.REACT_APP_APP_STORE_URL}>
                <img
                  src={Images.DOWNLOAD_APPLE}
                  style={{ height: '7vh', marginBottom: '1vh' }}
                  alt='Download for Apple'
                  title='Apple'
                />
              </a>
              <a href={process.env.REACT_APP_PLAY_STORE_URL}>
                <img
                  src={Images.DOWNLOAD_ANDROID}
                  style={{ height: '7vh' }}
                  alt='Download for Android'
                  title='Android'
                />
              </a>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default GetAppDialog;
