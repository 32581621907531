import React from 'react';

import { FaChevronDown } from 'react-icons/fa';

import { Ubutton } from '../../components/atoms/button';
import iphonePre from '../../assets/iphone-pre.png';
import phonesAndFaces from '../../assets/phones-faces.png';
import logoEvents from '../../assets/logo-events-light.svg';

import { Container } from './styles';

export const Events = () => {
  return (
    <Container>
      <section>
        <img src={logoEvents} alt='Umatch eventos' height={72} />
        <h2>Seu evento com o dobro de beijos e o triplo da saudade.</h2>

        <Ubutton
          utype='landingPage'
          style={{
            height: '3vh',
            width: '52vw',
            maxWidth: '300px',
            minWidth: '190px',
            maxHeight: '82px',
            minHeight: '52px',
            marginTop: '5vh',
          }}
          href={process.env.REACT_APP_WHATSAPP_LINK}
          text={
            <div
              style={{
                color: '#00A859',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                style={{ marginRight: 8 }}
              >
                <g clipPath='url(#clip0_106_128)'>
                  <path
                    d='M0 23.9992C0.300865 23.102 0.57256 22.2829 0.849255 21.4655C1.2468 20.2883 1.641 19.1095 2.05354 17.9373C2.13689 17.7039 1.99771 17.5602 1.9102 17.4048C1.25481 16.2477 0.809323 14.9845 0.594229 13.6732C0.181686 11.1959 0.494221 8.81583 1.63101 6.577C3.40286 3.0879 6.24148 0.932968 10.0802 0.220196C13.6806 -0.444394 16.9618 0.403789 19.7787 2.76474C22.8774 5.36246 24.2909 8.73026 23.9458 12.751C23.7505 15.0935 22.8441 17.3211 21.3475 19.1375C19.8508 20.9539 17.8347 22.273 15.5674 22.9193C12.5671 23.7865 9.66656 23.5232 6.86571 22.1292C6.51568 21.9548 6.23232 22.0462 5.91728 22.145C4.03431 22.7431 2.15078 23.3402 0.266695 23.9361C0.21919 23.9527 0.165851 23.961 0 23.9992ZM22.114 11.7782C22.1367 9.78833 21.5401 7.8403 20.4063 6.20233C17.8227 2.48479 13.2014 0.932969 8.93759 2.47814C5.02719 3.89538 2.82529 6.76474 2.35358 10.8869C2.09355 13.1614 2.72779 15.2673 3.98542 17.178C4.1521 17.4322 4.16043 17.6308 4.06876 17.8991C3.72789 18.8619 3.41119 19.8339 3.08115 20.8C2.99781 21.0335 3.03782 21.1074 3.29701 21.0235C4.32212 20.6912 5.35639 20.3822 6.37483 20.0308C6.49317 19.9835 6.62135 19.966 6.74808 19.9798C6.87481 19.9936 6.9962 20.0382 7.10157 20.1097C9.79185 21.6765 12.6138 21.9747 15.5608 20.9487C17.468 20.2795 19.1211 19.0394 20.2942 17.3978C21.4672 15.7563 22.1028 13.7936 22.114 11.7782V11.7782Z'
                    fill='#00A859'
                  />
                  <path
                    d='M6.45735 9.16393C6.44825 8.70912 6.53434 8.25742 6.71012 7.83761C6.8859 7.41781 7.14752 7.03913 7.47829 6.72572C7.82166 6.38512 8.67092 6.23974 9.11513 6.43912C9.27765 6.51139 9.36099 6.65095 9.42683 6.80796C9.73853 7.5451 10.0516 8.28169 10.3661 9.01772C10.405 9.10619 10.423 9.20241 10.4187 9.29893C10.4144 9.39544 10.3878 9.48967 10.3411 9.57432C10.1594 9.93984 9.89105 10.2348 9.61852 10.5288C9.44683 10.7149 9.41683 10.8819 9.55768 11.1195C10.4541 12.6602 11.8486 13.852 13.5131 14.4998C13.7564 14.5961 13.9406 14.5637 14.1023 14.366C14.3723 14.0337 14.6599 13.7155 14.9074 13.3691C15.1108 13.0817 15.3516 13.0028 15.6575 13.1448C16.4326 13.507 17.2026 13.8809 17.9752 14.2505C18.0408 14.2746 18.0979 14.3172 18.1395 14.3731C18.1811 14.429 18.2054 14.4959 18.2094 14.5654C18.2353 15.1751 18.1527 15.7724 17.7019 16.2177C16.7451 17.1623 15.6183 17.4489 14.3132 17.0402C12.6338 16.5151 11.0403 15.8431 9.75853 14.587C8.49673 13.35 7.36578 12.0117 6.69571 10.3502C6.53069 9.9548 6.43235 9.53527 6.45735 9.16393Z'
                    fill='#00A859'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_106_128'>
                    <rect width='24' height='24' fill='white' />
                  </clipPath>
                </defs>
              </svg>
              <text>CRIAR EVENTO</text>
            </div>
          }
          bold
        />

        <FaChevronDown />
      </section>

      <section>
        <div />

        <h2>Compartilhe o link ou código de acesso com seu público.</h2>

        <div />
      </section>

      <section>
        <h2>48h antes do evento, uma notificação automática chama todos para interagirem.</h2>

        <img src={iphonePre} alt='iPhone com notificação' />
      </section>

      <section>
        <h2>Seu público interage e dá match entre si!</h2>

        <img src={phonesAndFaces} alt='Celulares com rostos de usuários' />
      </section>

      <section>
        <img src={logoEvents} alt='Umatch eventos' height={72} />

        <Ubutton
          utype='landingPage'
          style={{
            height: '3vh',
            width: '52vw',
            maxWidth: '300px',
            minWidth: '190px',
            maxHeight: '82px',
            minHeight: '52px',
            marginTop: '5vh',
          }}
          href={process.env.REACT_APP_WHATSAPP_LINK}
          text={
            <div
              style={{
                color: '#00A859',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                style={{ marginRight: 8 }}
              >
                <g clipPath='url(#clip0_106_128)'>
                  <path
                    d='M0 23.9992C0.300865 23.102 0.57256 22.2829 0.849255 21.4655C1.2468 20.2883 1.641 19.1095 2.05354 17.9373C2.13689 17.7039 1.99771 17.5602 1.9102 17.4048C1.25481 16.2477 0.809323 14.9845 0.594229 13.6732C0.181686 11.1959 0.494221 8.81583 1.63101 6.577C3.40286 3.0879 6.24148 0.932968 10.0802 0.220196C13.6806 -0.444394 16.9618 0.403789 19.7787 2.76474C22.8774 5.36246 24.2909 8.73026 23.9458 12.751C23.7505 15.0935 22.8441 17.3211 21.3475 19.1375C19.8508 20.9539 17.8347 22.273 15.5674 22.9193C12.5671 23.7865 9.66656 23.5232 6.86571 22.1292C6.51568 21.9548 6.23232 22.0462 5.91728 22.145C4.03431 22.7431 2.15078 23.3402 0.266695 23.9361C0.21919 23.9527 0.165851 23.961 0 23.9992ZM22.114 11.7782C22.1367 9.78833 21.5401 7.8403 20.4063 6.20233C17.8227 2.48479 13.2014 0.932969 8.93759 2.47814C5.02719 3.89538 2.82529 6.76474 2.35358 10.8869C2.09355 13.1614 2.72779 15.2673 3.98542 17.178C4.1521 17.4322 4.16043 17.6308 4.06876 17.8991C3.72789 18.8619 3.41119 19.8339 3.08115 20.8C2.99781 21.0335 3.03782 21.1074 3.29701 21.0235C4.32212 20.6912 5.35639 20.3822 6.37483 20.0308C6.49317 19.9835 6.62135 19.966 6.74808 19.9798C6.87481 19.9936 6.9962 20.0382 7.10157 20.1097C9.79185 21.6765 12.6138 21.9747 15.5608 20.9487C17.468 20.2795 19.1211 19.0394 20.2942 17.3978C21.4672 15.7563 22.1028 13.7936 22.114 11.7782V11.7782Z'
                    fill='#00A859'
                  />
                  <path
                    d='M6.45735 9.16393C6.44825 8.70912 6.53434 8.25742 6.71012 7.83761C6.8859 7.41781 7.14752 7.03913 7.47829 6.72572C7.82166 6.38512 8.67092 6.23974 9.11513 6.43912C9.27765 6.51139 9.36099 6.65095 9.42683 6.80796C9.73853 7.5451 10.0516 8.28169 10.3661 9.01772C10.405 9.10619 10.423 9.20241 10.4187 9.29893C10.4144 9.39544 10.3878 9.48967 10.3411 9.57432C10.1594 9.93984 9.89105 10.2348 9.61852 10.5288C9.44683 10.7149 9.41683 10.8819 9.55768 11.1195C10.4541 12.6602 11.8486 13.852 13.5131 14.4998C13.7564 14.5961 13.9406 14.5637 14.1023 14.366C14.3723 14.0337 14.6599 13.7155 14.9074 13.3691C15.1108 13.0817 15.3516 13.0028 15.6575 13.1448C16.4326 13.507 17.2026 13.8809 17.9752 14.2505C18.0408 14.2746 18.0979 14.3172 18.1395 14.3731C18.1811 14.429 18.2054 14.4959 18.2094 14.5654C18.2353 15.1751 18.1527 15.7724 17.7019 16.2177C16.7451 17.1623 15.6183 17.4489 14.3132 17.0402C12.6338 16.5151 11.0403 15.8431 9.75853 14.587C8.49673 13.35 7.36578 12.0117 6.69571 10.3502C6.53069 9.9548 6.43235 9.53527 6.45735 9.16393Z'
                    fill='#00A859'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_106_128'>
                    <rect width='24' height='24' fill='white' />
                  </clipPath>
                </defs>
              </svg>
              <text>CRIAR EVENTO</text>
            </div>
          }
          bold
        />

        <footer />
      </section>
    </Container>
  );
};
