import React, { useState, useEffect } from 'react';
import { Images } from '../../config';
import Api from '../../services/api';
import { CheckCircleIcon, TimesCircleIcon, Container, Text, TextBold, ListText, ContentWrapper } from './styles';

export const UserActivated = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [approved, setApproved] = useState(false);

  useEffect(() => {
    const validateToken = async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get('token');

      if (token) {
        try {
          await Api.put('/activate', {
            token,
          });

          setApproved(true);
        } catch (e) {
          setLoading(false);
          setError(true);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        setError(true);
      }
    };

    validateToken();
  }, []);

  return (
    <Container>
      <img src={Images.UMATCH_WHITE_LOGO} alt='logo' />
      {!error && !loading && (
        <ContentWrapper>
          <CheckCircleIcon />
          <TextBold>Email confirmado!</TextBold>

          <Text>
            {approved
              ? 'Seu cadastro já foi aprovado! Você já pode ir lá curtir o app da Umatch.'
              : 'Agora é só voltar para o app da Umatch e começar a integração!'}
          </Text>
        </ContentWrapper>
      )}

      {error && !loading && (
        <ContentWrapper>
          <TimesCircleIcon />
          <TextBold>Link inválido</TextBold>
          <Text>Por favor, confira na sua caixa de entrada se: </Text>
          <ul>
            <ListText>Esse foi o último link que você recebeu da Umatch;</ListText>
            <ListText>Seu email já foi confirmado. Nesse caso, é só fazer login no app!</ListText>
          </ul>
        </ContentWrapper>
      )}
    </Container>
  );
};
