import React, { useState } from 'react';
import { Images } from '../../config';
import DynamicBackground from '../../components/organisms/dynamicBackground';
import { FooterWrapper, DownloadButtonsWrapper, Container, Content, Text } from './styles';

export const LandingPage = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <DynamicBackground
      imgIndex={0}
      changeImage
      handleMenuClick={openMenu}
      handleCloseMenu={closeMenu}
      openDrawer={menuOpen}
    >
      <Container>
        <Content>
          <h1>
            O <strong>melhor</strong> da sua vida <strong>universitária</strong>
          </h1>
          <Text>
            Dê <strong>match</strong>. Faça <strong>amigos</strong>. Marque <strong>encontros</strong>.
          </Text>
          <DownloadButtonsWrapper>
            <a href={process.env.REACT_APP_APP_STORE_URL}>
              <img src={Images.DOWNLOAD_APPLE} alt='Download for Apple' title='Apple' />
            </a>
            <a href={process.env.REACT_APP_PLAY_STORE_URL}>
              <img
                src={Images.DOWNLOAD_ANDROID}
                alt='Download for Android'
                title='Android'
                style={{ marginRight: 0 }}
              />
            </a>
          </DownloadButtonsWrapper>
        </Content>
      </Container>
      <FooterWrapper>Copyright © Umatch {new Date().getFullYear()}</FooterWrapper>
    </DynamicBackground>
  );
};
