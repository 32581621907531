import styled from 'styled-components';
import { Colors } from '../../config';

export const Container = styled.div`
  display: flex;

  background-color: ${Colors.PRIMARY};
  height: 100vh;

  flex-direction: column;

  padding: 16px;

  @media (min-width: 1024px) {
    justify-content: center;
    padding: 0 25%;
  }

  img {
    margin-right: auto;
  }

  h1 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;

    margin: 32px 0 16px;
  }

  button {
    height: 54px;
    border-radius: 30px;
    background: #f1f1f1;
    border-color: #f1f1f1;
    border-style: solid;
    margin: auto 16px 40px;

    color: ${Colors.PRIMARY};
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    font-weight: 600;

    :disabled {
      border-color: transparent;
      cursor: not-allowed;
    }

    @media (min-width: 1024px) {
      margin: 32px 0;
    }
  }
`;
