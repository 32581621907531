import styled from 'styled-components';
import womenBg from '../../assets/women-bg.png';
import linksTop from '../../assets/links-top.png';
import linksBottom from '../../assets/links-bottom.png';
import { Colors } from '../../config';

export const Container = styled.main`
  display: flex;
  flex-direction: column;

  section {
    height: 100vh;
    width: 100%;

    h2 {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
    }

    button {
      width: 200px;
    }
  }

  > section:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: ${Colors.PRIMARY};
    padding: 0 40px;

    background-image: url(${womenBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
    background-position-x: 50%;

    @media (min-width: 768px) {
      background-image: none;
    }
  }

  // get second section
  > section:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: ${Colors.PRIMARY};

    // get first div
    > div:first-child {
      height: 33%;
      width: 100%;
      background-image: url(${linksTop});
      background-repeat: no-repeat;
      background-size: contain, cover;
      background-position-y: 65%;

      @media (min-width: 768px) {
        background-repeat: repeat-x;
      }
    }

    h2 {
      padding: 0 40px;
    }

    > div:last-child {
      height: 33%;
      width: 100%;
      background-image: url(${linksBottom});
      background-repeat: no-repeat;
      background-size: contain, cover;
      background-position-y: 10%;

      @media (min-width: 768px) {
        background-repeat: repeat-x;
      }
    }
  }

  > section:nth-child(3) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: ${Colors.LIGHT_BLUE};
  }

  > section:nth-child(4) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${Colors.PRIMARY};

    padding: 32px 0;

    h2 {
      padding: 0 40px;
    }

    img {
      height: 90%;
    }
  }

  > section:nth-child(5) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    padding-top: 64px;
    justify-content: space-between;
    background-color: ${Colors.PRIMARY};

    footer {
      height: 66px;
      width: 100%;
      background-color: ${Colors.LIGHT_BLUE};
    }
  }
`;
