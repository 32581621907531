import appSpoiler from './assets/appSpoiler.png';
import downloadApple from './assets/download-on-app-store.svg';
import downloadGoogle from './assets/get-it-on-google-play.svg';
import whiteLogo from './assets/umatch-white-logo.png';
import appDemo from './assets/iphone-white.png';

export const Colors = {
  PRIMARY: '#EF3A5E ',
  SECONDARY: '#EE2D48',
  LIGHT_BLUE: '#2DAABA',
  BLACK: '#121212',
  WHITE: '#F1F1F1',
  MIDDLE_GRAY: '#67686b',
  GRAY: '#8f8f8f',
  GRAY_SEMI_DARK: '#2c2c2c',
  GRAY_DARK: '#24252b',
};

export const MAIN_TEXT_FONT = 'Montserrat';

export const LANDING_PAGE_FONT = 'Montserrat';

// <<<<<<<<<<<<<< IMAGES >>>>>>>>>>>>>>
export const Images = {
  APP_SPOILER: appSpoiler,
  DOWNLOAD_APPLE: downloadApple,
  DOWNLOAD_ANDROID: downloadGoogle,
  UMATCH_WHITE_LOGO: whiteLogo,
  APP_LANDING_PAGE: appDemo,
};

export const FORMDATA_CONFIG = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};
