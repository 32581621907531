import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../hooks/queryParams';
import appStore from '../../assets/app-store.svg';
import playStore from '../../assets/google-play.svg';
import clipboardIcon from '../../assets/clipboard.svg';
import checkIcon from '../../assets/check.svg';
import brandLogo from '../../assets/logo-primary-color.svg';

import {
  Container,
  Content,
  DownloadApp,
  StoreImage,
  HeartBrokenIcon,
  Loader,
  H1,
  H2,
  H3,
  LogoWrapper,
  CopyButtonContainer,
  CopyButton,
  CopyButtonLabel,
  ErrorWrapper,
  EventExplanation,
  Button,
  EventWrapper,
} from './styles';
import api from '../../services/api';
import { useClipboard } from '../../hooks/clipboard';

const today = new Date();

export const SignUp = () => {
  const { token } = useParams();
  const editedToken = token.split('&email=')[0];

  const query = useQuery();

  const [hasCopied, handleCopy] = useClipboard(editedToken ?? '');
  const [loading, setLoading] = useState(true);
  const [isInviteInvalid, setIsInviteInvalid] = useState(false);
  const [isInviteExpired, setIsInviteExpired] = useState(false);
  const [inviteInfo, setInviteInfo] = useState(null);
  const [eventName, setEventName] = useState('');

  useEffect(() => {
    const checkToken = async () => {
      try {
        const { data } = await api.get(`/invites/${editedToken}`);

        const isExpired = new Date(data.expiration) <= today;
        const isUserInvite = data.user_id !== null;

        setIsInviteExpired(isExpired);
        setInviteInfo({ ...data, in: query.get('in'), isUserInvite });

        if (data.event_short_name) {
          setEventName(data.event_short_name);
        }
      } catch (error) {
        setIsInviteInvalid(true);
      } finally {
        setLoading(false);
      }
    };

    checkToken();
  }, []);

  const iOS = () => {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  };

  const appDeepLinkPrefix = iOS() ? 'umatch://' : 'umatch://umatch/';

  const takeToApp = () => {
    const storeUrl = iOS() ? process.env.REACT_APP_APP_STORE_URL : process.env.REACT_APP_PLAY_STORE_URL;

    const takeToStoreTimeout = setTimeout(() => {
      window.location = storeUrl;
    }, 1000);

    if (window.onblur) {
      clearTimeout(takeToStoreTimeout);
    }
  };

  return (
    <Container>
      <Content>
        <LogoWrapper isError={isInviteInvalid || isInviteExpired}>
          <img src={brandLogo} alt='Umatch' height={32} />
        </LogoWrapper>
        {loading ? (
          <>
            <Loader />
          </>
        ) : isInviteInvalid || isInviteExpired || !inviteInfo ? (
          <ErrorWrapper>
            <H3>Opa, não encontramos o seu convite... Verifique se você possui um link válido!</H3>
            <HeartBrokenIcon />
          </ErrorWrapper>
        ) : eventName ? (
          <EventWrapper>
            <H1 mt>{eventName} na Umatch</H1>

            <EventExplanation>
              <span>
                48 horas antes do evento
                <br />
                descubra e dê match com
                <br />
                todos que vão na festa
              </span>
            </EventExplanation>

            <a href={`${appDeepLinkPrefix}event/${editedToken}`} onClick={takeToApp}>
              <Button type='button' primary>
                Já estou na Umatch
              </Button>
            </a>
            <Button type='button' onClick={() => setEventName(null)}>
              Ainda não estou na Umatch
            </Button>
          </EventWrapper>
        ) : (
          <>
            <H1>Baixe o app!</H1>
            <H2>Copie o código de convite abaixo e use-o para criar a sua conta:</H2>
            <CopyButtonContainer hasCopied={hasCopied}>
              <CopyButton onClick={handleCopy}>
                {editedToken}
                {hasCopied ? (
                  <img src={checkIcon} alt='check-icon' />
                ) : (
                  <img src={clipboardIcon} alt='clipboard-icon' />
                )}
              </CopyButton>
              {hasCopied && <CopyButtonLabel>Código copiado!</CopyButtonLabel>}
            </CopyButtonContainer>
            <DownloadApp>
              <a href='https://apps.apple.com/app/id1524234730'>
                <StoreImage src={appStore} alt='Download for Apple' title='Apple' />
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.uapp'>
                <StoreImage src={playStore} alt='Download for Android' title='Android' style={{ marginRight: 0 }} />
              </a>
            </DownloadApp>
          </>
        )}
      </Content>
    </Container>
  );
};
