import React, { useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import { useLocation } from 'react-router-dom';
import { Container } from './styles';
import logo from '../../assets/logo.svg';
import api from '../../services/api';

const schema = yup.object({
  password: yup.string().min(8, 'Senha precisa ter no mínimo 8 caracteres').required('Senha é obrigatória'),
  password_confirmation: yup.string().oneOf([yup.ref('password'), null], 'Senhas não conferem'),
});

export const ForgotPassword = () => {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [status, setStatus] = useState('initial');

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const token = query.get('token');

  const onSubmit = async data => {
    setStatus('loading');

    try {
      await api.put('/forgotpassword', {
        token,
        new_password: data.password,
      });

      setStatus('success');
    } catch (error) {
      setStatus('error');
    }
  };

  if (status === 'error') {
    return (
      <Container>
        <img src={logo} alt='Umatch' />
        Parece que ocorreu um erro, tente novamente. Caso persista, fale com nosso suporte em{' '}
        <a
          style={{
            ontSize: '16px',
            color: '#f1f1f1',
          }}
          href='mailto:contato@umatch.com.br'
        >
          contato@umatch.com.br
        </a>
        <form>
          <button type='button' onClick={() => setStatus('initial')}>
            Tentar novamente
          </button>
        </form>
      </Container>
    );
  }

  if (status === 'success') {
    return (
      <Container>
        <img src={logo} alt='Umatch' />

        <p>Sua senha foi atualizada.</p>

        <br />
        <p>Corre lá pra curtir o app!</p>
      </Container>
    );
  }

  return (
    <Container>
      <img src={logo} alt='Umatch' />
      <p>Escreva sua nova senha abaixo:</p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <input {...register('password')} type='password' placeholder='Senha' />
        <p>{errors.password?.message}</p>

        <input {...register('password_confirmation')} type='password' placeholder='Confirme a senha' />
        <p>{errors.password_confirmation?.message}</p>

        <button disabled={status === 'loading'} type='submit'>
          {status === 'loading' ? 'Trocando senha...' : 'Trocar senha'}
        </button>
      </form>
    </Container>
  );
};
