import { Toolbar, IconButton, Grid } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import * as React from 'react';
import { Link } from 'react-router-dom';
import LogoAppBar from '../../atoms/logoAppBar';
import { StyledAppBar, StyledAppBarGray } from './styles';
import Logo from '../../atoms/logo';

const AppBar = ({ isLandingPage, position, handleMenuClick }) => {
  return (
    <div>
      {isLandingPage && (
        <StyledAppBar position={position || 'static'}>
          <Toolbar>
            <Grid container justify='space-between' alignItems='center'>
              <Grid item>
                <Link to='/' style={{ textDecoration: 'none' }}>
                  <Logo
                    style={{
                      height: '6vh',
                      width: 'auto',
                      marginLeft: '1vh',
                      marginRight: '1vh',
                      marginBottom: '2vh',
                      marginTop: '2vh',
                      maxHeight: '45px',
                    }}
                  />
                </Link>
              </Grid>
              <Grid item>
                <IconButton edge='start' color='inherit' aria-label='menu' onClick={handleMenuClick}>
                  <Menu style={{ color: 'white', fontSize: 40 }} />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </StyledAppBar>
      )}
      {!isLandingPage && (
        <StyledAppBarGray position={position || 'static'}>
          <Toolbar>
            <Grid container justify='space-between' alignItems='center'>
              <Grid item>
                <Link to='/' style={{ textDecoration: 'none' }}>
                  <LogoAppBar />
                </Link>
              </Grid>
              <Grid item>
                <IconButton edge='start' color='inherit' aria-label='menu' onClick={handleMenuClick}>
                  <Menu style={{ color: 'white', fontSize: 40 }} />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </StyledAppBarGray>
      )}
    </div>
  );
};

export default AppBar;
