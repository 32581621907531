import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  width: 100%;
  text-align: center;
`;

export const Content = styled.div`
  padding: 24px;

  text-align: center;

  flex-direction: column;

  h1 {
    font-size: 2rem;
    margin-bottom: 16px;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  opacity: 50%;

  font-size: 0.75rem;
`;

export const DownloadButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  align-items: center;
  margin-top: 16px;

  img {
    display: block;
    height: 64px;
    margin-top: 16px;
  }
`;

export const Text = styled.p`
  font-size: 1rem;
`;
