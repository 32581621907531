export const questions = [
  {
    title: 'Excluir/Pausar conta',
    description: `É possível deletar ou pausar sua conta de dentro do app.

Ao deletar a conta, tenha em mente que você perderá todas as informações como matches e conversas.

Ao pausar a conta, você não aparecerá mais para outros usuários, mas não perderá matches, conversas, fotos, informações de perfil, etc., podendo voltar a usar a sua conta a qualquer momento.

Para deletar a conta, basta acessar a aba de configurações do app, clicar em <strong>Sair do app</strong> e depois em <strong>Apagar conta</strong>.

Para pausá-la, basta clicar em <strong>Sair do app</strong>, em <strong>Apagar conta</strong> e por fim em <strong>Pausar conta</strong>.`,
  },
  {
    title: 'Recompensas por convidar amigo',
    description: `Para você receber os benefícios por trazer um amigo, ele deve finalizar o cadastro e utilizar o app ao menos uma vez.

Assim que ele entrar pela primeira vez, você receberá uma mensagem da Nina lhe informando que você recebeu os benefícios.

Se voce ainda não recebeu nada, cutuque o coleguinha, porque provavelmente ele ainda não terminou o cadastro ou ainda não usou o app. 😬

PS.: ao receber a mensagem da Nina, lembre de coletar seu objetivo semanal, já que toda terça-feira os objetivos são renovados!`,
  },
  {
    title: 'Cancelar assinatura',
    description: `O cancelamento das assinaturas não é feito diretamente por nós da Umatch, assim como o gerenciamento de pagamentos de quaisquer compras dentro do nosso app.

O processamento de assinaturas é feito pela loja de aplicativos do seu celular (Google Play/App Store).

Por ser uma assinatura recorrente, há uma renovação automática a menos que você desabilite ou cancele essa funcionalidade dentro da própria loja.

Nós da Umatch não recebemos suas informações de pagamento em nenhum momento, por esse motivo não conseguimos cancelar a sua compra por aqui. Então, se tiver algum problema, entre em contato com o suporte da loja de aplicativos do seu celular!

Aqui estão alguns links que podem te ajudar:

<a href='https://support.apple.com/pt-pt/HT202039' target="_blank">App Store</a>

<a href='https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=pt-BR' target="_blank">Google Play</a>`,
  },
  {
    title: 'Restaurar assinatura',
    description: `Caso já tenha uma assinatura ativa em sua conta da Apple/Google e tenha criado uma nova conta na Umatch, será necessário restaurar a assinatura para atrelá-la a nova conta.

Para atrelar a assinatura à nova conta na Umatch, basta entrar na página de Configurações do nosso app, clicar em <strong>Restaurar compras</strong> e reiniciar seu aplicativo.`,
  },
  {
    title: 'Redefinição de senha',
    description: `Caso você esteja tentando redefinir sua senha, mas esteja enfrentando erros, certifique-se de estar digitando o e-mail corretamente..

Muitos dos nossos usuários utilizam endereços de email universitários para a criação da conta, e se esquecem disso na hora de recuperar a senha. Certifique-se de que está tentando restaurar a conta para o email correto.

Não é possível redefinir a senha de uma conta que foi deletada anteriormente. Caso tenha deletado a sua conta, você precisará criar uma nova conta.`,
  },
  {
    title: 'O que é o pausar conta?',
    description: `Pausar conta é a funcionalidade que torna o seu perfil invisível para outras pessoas no app, mas permitindo com que sua conta continue existindo, sem apagar suas informações. Caso queira voltar a usar o app, é possível despausar a sua conta a qualquer momento.

Para pausar sua conta, vá à página de Configurações do aplicativo e clique em Pausar conta.`,
  },
  {
    title: 'Posso fazer uma assinatura por fora da Play Store/App Store?',
    description: `Não é possível realizar uma assinatura da Umatch por fora da loja de aplicativos de seu respectivo aparelho. Todos os pagamentos são processados pela Google/Apple e seguem as regras de cobrança de cada uma das lojas de aplicativos.

A Umatch não recebe em nenhum momento suas informações de cobrança.`,
  },
  {
    title: 'E-mail universitário não possui caixa de entrada',
    description: `Caso seu e-mail universitário não possua caixa de entrada, você poderá optar por um e-mail não institucional. Tenha em mente que, ao utilizar um e-mail não universitário, será necessário comprovar o vínculo com a universidade através do envio de documentos.`,
  },
  {
    title: 'Errei meu e-mail e não consigo confirmar',
    description: `Você precisará criar uma nova conta com o endereço de e-mail correto.`,
  },
  {
    title: 'Meu convite expirou',
    description: `Você poderá pedir um novo convite de onde você conseguiu o primeiro, ou preencher o formulário para acessar o app sem um convite.`,
  },
  {
    title: 'Meu convite não funciona',
    description: `ocê poderá pedir um novo convite de onde você conseguiu o primeiro, ou preencher o formulário para acessar o app sem um convite.`,
  },
  {
    title: 'Conta Banida',
    description: `Nosso sistema de banimento é automático. Se sua conta foi banida, significa que usuários a denunciaram. Caso tenha dúvidas sobre os motivos, recomendamos dar uma olhada em nossos termos de uso.

Por fim, caso você acredite que o banimento foi um equívoco, entre em contato com o email <strong>contato@umatch.com.br</strong> com o assunto <strong>Revisão de banimento</strong> e o seu endereço de email cadastrado na Umatch no corpo do email.`,
  },
  {
    title: 'Confirmação de email',
    description: `Caso você esteja tendo problemas para receber ou achar o e-mail de confirmação, tente reenviá-lo através do próprio aplicativo. Na tela de confirmação de e-mail temos um botão para disparar o envio novamente, o que deve funcionar em grande parte dos casos e pode demorar alguns minutos.

Entretanto, caso você realmente não esteja recebendo mesmo após checar sua Caixa de Entrada e sua pasta de Spam, envie-nos um email do endereço de email cadastrado na Umatch com o assunto <strong>Confirmação de email</strong> para <strong>contato@umatch.com.br</strong> e confirmaremos seu email manualmente.`,
  },
  {
    title: 'Cadastro rejeitado',
    description: `Caso seu cadastro tenha sido rejeitado, procure seguir a explicação da rejeição (disponível tanto em seu app como no e-mail de rejeição enviado) para que, na próxima vez, seu cadastro seja aprovado e você vire Umatcher logo!

Se o motivo da rejeição mencionar o problema de conta duplicada, isso aconteceu pois foi identificado um usuário com informações quase idênticas às que você colocou em seu cadastro, o que indica que você já fez uma conta antes.

Aí você pode escolher uma das duas opções:

<strong>Voltar a utilizar a sua conta antiga:</strong>
Para continuar com sua conta antiga, basta fazer login utilizando seu e-mail cadastrado nela.

Caso tenha esquecido sua senha, clique em <strong>Deu branco na senha</strong> embaixo do campo de senha.

<strong>Deletar a sua conta antiga para que você consiga cadastrar sua conta nova:</strong>
Para criar uma nova conta com outro e-mail, você deve primeiro excluir a conta antiga.

Para excluir a conta antiga, basta fazer login, ir na aba de configurações, clicar em <strong>Sair do app</strong> e depois em <strong>Apagar conta</strong>.

Tenha em mente que você perderá todas as informações como matches e conversas na conta antiga.`,
  },
  {
    title: 'Alterar informações da conta (nome, curso, etc.)',
    description: `Para alterar suas informações, acesse as configurações do app e clique em <strong>Solicitar mudança de informação</strong>.`,
  },
  {
    title: 'Bugs',
    description: `Para relatar um bug, acesse as configurações do app e clique em <strong>Relatar um bug</strong>.`,
  },
  {
    title: 'Outros',
    description: `Caso não tenha encontrado sua dúvida, entre em contato no e-mail <strong>contato@umatch.com.br</strong>`,
  },
];
