import styled from 'styled-components';
import { FaHeartBroken } from 'react-icons/fa';
import { Colors } from '../../config';

const LOGO_BASE_MARGIN_BOTTOM = 32;

export const Container = styled.main`
  width: 100%;
  background-color: ${Colors.PRIMARY};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 48px 0;

  height: 100vh;
  background-color: ${Colors.BLACK};

  > h2 {
    font-size: 1.125rem;
    margin-bottom: ${LOGO_BASE_MARGIN_BOTTOM}px;
    text-align: center;
    z-index: 1;
  }
`;

export const LogoWrapper = styled.div`
  margin-bottom: ${({ isError }) => (isError ? 0 : LOGO_BASE_MARGIN_BOTTOM)}px;
  z-index: 1;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  @media (max-width: 768px) {
    margin-top: auto;
  }

  width: 80%;
  margin-left: 10%;
`;

export const HeartBrokenIcon = styled(FaHeartBroken)`
  width: 64px;
  height: 64px;
  color: ${Colors.PRIMARY};
`;

export const Loader = styled.div`
  border: 3px solid ${Colors.PRIMARY};
  border-top: 3px solid transparent;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  animation: spin 2s linear infinite;

  margin: auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
`;

export const H1 = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 16px;
  text-align: center;
`;

export const H2 = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 16px;
  font-weight: 500;
  text-align: center;
`;

export const H3 = styled.h3`
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 64px;
`;

export const CopyButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 300px;

  margin-top: 16px;
  margin-bottom: ${({ hasCopied }) => (hasCopied ? '42px' : '64px')};
`;

export const CopyButtonLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 8px;
  line-height: 1;
`;

export const CopyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  width: 100%;

  border-radius: 8px;
  border: dashed 1px ${Colors.WHITE};

  background-color: ${Colors.GRAY_DARK};
  color: ${Colors.WHITE};

  font-size: 1.125rem;
  font-weight: 600;
  text-transform: uppercase;

  :focus {
    outline: none;
  }
`;

export const DownloadApp = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;

  gap: 16px;
`;

export const StoreImage = styled.img`
  display: block;
  height: 64px;
`;

export const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
`;

export const EventExplanation = styled.div`
  border-radius: 8px;
  padding: 12px;

  display: flex;
  align-items: center;
  text-align: center;

  span {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: ${Colors.WHITE};
  }

  button {
    padding: 8px 16px;
    border-radius: 30px;
    background: transparent;

    width: 100%;

    margin-top: 16px;
    color: ${Colors.WHITE};
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    font-weight: 600;

    :disabled {
      border-color: transparent;
      cursor: not-allowed;
    }

    span {
      color: ${Colors.BLACK};
    }
  }
`;

export const Button = styled.button`
  width: 300px;
  max-width: 100%;

  padding: 16px 16px;
  margin-top: ${({ primary }) => (primary ? 32 : 16)}px;
  align-self: center;

  border: none;
  border-radius: 32px;

  color: ${({ primary }) => (primary ? Colors.BLACK : Colors.PRIMARY)};
  background-color: ${({ primary }) => (primary ? Colors.PRIMARY : 'transparent')};
  cursor: pointer;

  font-weight: 600;
  text-transform: uppercase;
`;
