import React from 'react';
import { Switch } from 'react-router-dom';

import { Route } from './Route';

import { AboutUs } from '../screens/AboutUs';
import { Events } from '../screens/Events';
import { Faq } from '../screens/Faq';
import { ForgotPassword } from '../screens/ForgotPassword';
import { LandingPage } from '../screens/LandingPage';
import { MailUnsubscribe } from '../screens/MailUnsubscribe';
import { Privacy } from '../screens/Privacy';
import { Register } from '../screens/Register';

import { TermsOfService } from '../screens/TermsOfService';
import { UserActivated } from '../screens/UserActivated';
import { SignUp } from '../screens/SignUp';

const Routes = () => {
  return (
    <Switch>
      <Route component={TermsOfService} exact path='/terms-of-service' />
      <Route component={Faq} exact path='/faq' />

      <Route component={ForgotPassword} path='/new-password' />
      <Route component={Privacy} path='/privacy' />
      <Route component={UserActivated} path='/user-activated' />
      <Route component={AboutUs} path='/about-us' />

      <Route component={Register} exact path='/register' />
      <Route component={Register} exact path='/instagram' />

      <Route component={SignUp} exact path='/signup/:token' />

      <Route component={Events} exact path='/umatcheventos' />

      <Route component={MailUnsubscribe} exact path='/mail/unsubscribe' />

      <Route component={LandingPage} path='/' />
    </Switch>
  );
};

export default Routes;
