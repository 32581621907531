import React from 'react';
import DynamicBackgroundVideo from '../../../assets/videos/dynamicBackgroundVideo.mp4';
import AppBarWithDrawer from '../appBarWithDrawer';

const DynamicBackground = ({ handleMenuClick, handleCloseMenu, openDrawer, children }) => {
  return (
    <>
      <video
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          width: '100%',
          left: '50%',
          top: '50%',
          height: '100%',
          objectFit: 'cover',
          transform: 'translate(-50%, -50%)',
          zIndex: '-2',
        }}
        playsInline
      >
        <source src={DynamicBackgroundVideo} type='video/mp4' />
      </video>
      <div
        style={{
          color: 'white',
          overflowX: 'hidden',
          background:
            'linear-gradient(72.01deg, #EE3A5E 0%, #EC3A5E 42.05%, #E5395E 57.44%, #D9385E 68.46%, #C8365E 77.39%, #B2345E 85.05%, #97315F 91.84%, #772E5F 97.84%, #6A2D5F 100%)',
          opacity: 0.85,
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: '-1',
        }}
      />
      <AppBarWithDrawer
        handleMenuClick={handleMenuClick}
        handleCloseMenu={handleCloseMenu}
        openDrawer={openDrawer}
        isLandingPage
      />
      {children}
    </>
  );
};

export default DynamicBackground;
