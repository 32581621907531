import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const Register = () => {
  const history = useHistory();
  const params = new URLSearchParams(location.search);

  const partnerInviteToken = params.get('tp');
  const userInviteToken = params.get('tu');

  const inviteToken = partnerInviteToken ?? userInviteToken;

  useEffect(() => {
    history.push(`/signup/${inviteToken}`);
  }, [inviteToken]);

  return null;
};
