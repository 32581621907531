import { Button } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { Colors } from '../../../config';

const btnColors = kind => {
  const btnTypes = {
    primary: {
      bg: `linear-gradient(90deg, ${Colors.PRIMARY} 0%, ${Colors.SECONDARY} 100%)`,
    },
    blue: { bg: 'linear-gradient(90deg, #73c9ce 0%, #1eaab3 100%)' },
    gray: { bg: Colors.GRAY, opacity: 0.6 },

    green: { bg: 'linear-gradient(90deg, #73cead 0%, #1eb38f 100%)' },

    darkBlue: {
      bg: 'linear-gradient(90deg, #2067d0 0%, #1a2e9a 100%)',
    },
    goBack: { bg: Colors.GRAY },
    registerWelcome: { bg: '#FFFFFF' },
    registerSubmit: { bg: '#FFFFFF' },
    landingPage: {
      bg: 'white',
    },
    landingPage2: {
      bg: '#2311238a ',
    },
  };

  return btnTypes[kind];
};

export const UbuttonStyledCss = css`
  color: ${props =>
    props.utype === 'registerWelcome'
      ? `${Colors.PRIMARY} !important`
      : props.utype === 'registerSubmit'
      ? `${Colors.LIGHT_BLUE} !important`
      : props.utype === 'landingPage'
      ? `${Colors.PRIMARY} !important`
      : 'white !important'};
  font-weight: ${props => (props.bold ? '600 !important' : '400 !important')};
  font-size: 14px !important;
  text-transform: uppercase !important;
  background: ${props =>
    props.utype === 'goBack'
      ? `${Colors.GRAY} !important`
      : props.utype
      ? `${btnColors(props.utype).bg} !important`
      : btnColors('primary').bg};

  border-width: ${props => (props.utype === 'landingPage2' ? '4px !important' : '0px')};
  border-color: ${props => (props.utype === 'landingPage2' ? 'white !important' : null)};

  padding: 2vh 2vw !important;
  border-radius: 30px !important;
  opacity: ${props => (props.disabled ? 0.6 : 1)};
`;

export const ButtonStyled = styled(Button)`
  ${UbuttonStyledCss}

  &:focus {
    outline: 0;
  }
`;
