import styled from 'styled-components';
import CheckCircle from '@material-ui/icons/CheckCircle';
import TimesCircle from '@material-ui/icons/Cancel';
import { Colors } from '../../config';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 0 32px;

  background-color: ${Colors.BLACK};
  align-items: center;

  img {
    width: 160px;
    padding-top: 100px;
    padding-bottom: 84px;
  }
`;

export const ContentWrapper = styled(Container)`
  max-width: 75ch;
`;

export const Text = styled.p`
  color: ${Colors.WHITE};
  font-size: 1.25rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 16px;
`;

export const TextBold = styled(Text)`
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 24px;
`;

export const ListText = styled.li`
  font-size: 1.125rem;
  margin-bottom: 8px;
`;

export const CheckCircleIcon = styled(CheckCircle)`
  font-size: 3rem !important;
  color: ${Colors.PRIMARY};
  margin-bottom: 40px;
`;

export const TimesCircleIcon = styled(TimesCircle)`
  font-size: 3rem !important;
  color: ${Colors.PRIMARY};
  margin-bottom: 40px;
`;
