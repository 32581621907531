import React from 'react';

import { Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';

const UDrawer = ({ openDrawer, handleCloseMenu }) => {
  return (
    <Drawer anchor='right' onClose={handleCloseMenu} open={openDrawer} PaperProps={{ width: 250 }}>
      <div style={{ width: 250 }}>
        <List>
          <ListItem button onClick={handleCloseMenu}>
            <CloseIcon style={{ fontSize: 40 }} />
          </ListItem>
          <Link to='/about-us' style={{ textDecoration: 'none', color: 'black', fontFamily: 'montserrat' }}>
            <ListItem button>
              <ListItemText primary='Sobre nós' />
            </ListItem>
          </Link>
          <Link to='/privacy' style={{ textDecoration: 'none', color: 'black', fontFamily: 'montserrat' }}>
            <ListItem button>
              <ListItemText primary='Privacidade' />
            </ListItem>
          </Link>
          <Link to='/terms-of-service' style={{ textDecoration: 'none', color: 'black', fontFamily: 'montserrat' }}>
            <ListItem button>
              <ListItemText primary='Termos de uso' />
            </ListItem>
          </Link>
          <Link to='/faq' style={{ textDecoration: 'none', color: 'black', fontFamily: 'montserrat' }}>
            <ListItem button>
              <ListItemText primary='Perguntas frequentes' />
            </ListItem>
          </Link>
        </List>
      </div>
    </Drawer>
  );
};

export default UDrawer;
