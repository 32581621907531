import { AppBar } from '@material-ui/core';
import styled from 'styled-components';
import { Colors } from '../../../config';

export const StyledAppBar = styled(AppBar)`
  background-color: #ed335a00 !important;
  opacity: 100%;
  box-shadow: none !important;
`;

export const StyledAppBarGray = styled(AppBar)`
  background-color: ${Colors.BLACK} !important;
  opacity: 100%;
  box-shadow: none !important;
`;
