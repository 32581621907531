import React, { useState } from 'react';
import AppBarWithDrawer from '../../components/organisms/appBarWithDrawer';
import { Container, Content } from './styles';
import { Footer } from '../../components/organisms/footer';

export const Privacy = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Container>
      <AppBarWithDrawer
        handleMenuClick={openMenu}
        handleCloseMenu={closeMenu}
        openDrawer={menuOpen}
        appBarPosition='fixed'
      />

      <Content>
        <h1>Política de Privacidade</h1>
        {/* ============= INTRODUÇÃO ============= */}
        <p>
          Bem-vindo à <strong>Política de Privacidade da Umatch</strong>. Agradecemos por reservar um tempo para lê-la.{' '}
          <br /> <br />
          Entendemos que você nos confia as suas informações e zelamos por essa confiança. Por isso, neste documento,
          explicamos as informações que coletamos, o motivo pela qual as coletamos, como são usadas e os seus direitos
          em relação às suas informações. <br />
          <br />
          <strong>Atualização: </strong>31/08/2020
        </p>
        {/* ============= QUEM SOMOS ============= */}
        <h2>1. Quem somos</h2>
        <p>
          A empresa responsável pelas suas informações ao abrigo desta Política de Privacidade (o "controlador de
          dados") é: <br />
          <br />
          <strong>UMATCH LTDA</strong>
          <br />
          CNPJ: 38.822.591/0001-55
          <br />
          AV BRIG FARIA LIMA, 2369
          <br />
          SÃO PAULO - SP
          <br /> <br />A presente <strong>Políica de Privacidade</strong> aplica-se a websites, aplicativos, eventos e
          outros serviços operados pela Umatch. Por questão de simplicidade, referimo-nos a todos como "serviços" nesta
          Política de Privacidade.
        </p>
        {/* ============= INFORMAÇÕES QUE COLETAMOS ============= */}
        <h2>2. Informações que coletamos</h2>
        <p>
          Para que possamos confirmar que é universitário e então conseguirmos ajudá-lo a criar novas relações
          significativas é evidente que necessitamos obter alguma informação pessoal. Também coletamos informações
          geradas através da sua utilização dos nossos serviços. Se quer obter mais informações, entraremos em detalhes
          a seguir.
        </p>
        <strong>Informações que você nos fornece</strong>
        <p>
          O fornecimento de informações a nós ao utilizar nossos serviços é escolha sua, o que inclui:
          <ul>
            <li>
              Ao criar uma conta, você nos fornece o nome de sua universidade, seu nome, email, data de nascimento,
              senha na Umatch, gênero, preferência sexual e, caso não possamos confirmar que é universitário através de
              seu email, documento(s) que comprove estar matriculado na universidade que alegou ou que graduou-se a
              menos de 1 ano nesta. Dados relativos à vida sexual ou à orientação sexual do usuário são considerados
              sensiveis segundo a LGPD (Lei Geral de Proteção de Dados). Ao optar por nos fornecer essas informações,
              você autoriza o nosso processamento destas.
            </li>
            <li>
              Ao completar o seu perfil, você pode compartilhar informações adicionais conosco, como descrição de seu
              perfil e fotografias. Para adicionar determinado conteúdo, por exemplo, imagens, você pode permitir o
              acesso à sua câmera ou álbum de fotografias. Algumas das informações que você optar por nos fornecer podem
              ser consideradas "especiais" ou "confidenciais" em determinadas jurisdições, como as suas origens étnicas
              ou raciais, orientação sexual e crenças religiosas. Novamente, ao optar por nos fornecer essas
              informações, você autoriza o nosso processamento destas.
            </li>
            <li>
              Quando você participa de pesquisas ou discussões em grupo, você nos fornece as suas opiniões sobre os
              nossos produtos e serviços, respostas às nossas perguntas e depoimentos.
            </li>
            <li>
              Quando você opta por participar de nossas promoções, eventos ou concursos, coletamos as informações
              utilizadas para realizar o seu cadastro ou para participar.
            </li>
            <li>
              Se você entra em contato com a nossa equipe de atendimento ao cliente, coletamos as informações fornecidas
              durante a interação.
            </li>
            <li>Também processamos as suas conversas com outros usuários, como parte da operação dos serviços.</li>
          </ul>
        </p>
        <strong>Informações que recebemos de terceiros</strong>
        <p>
          Além das informações que você nos fornece diretamente, recebemos informações a seu respeito a partir de
          terceiros, incluindo:
          <ul>
            <li>
              <strong>Outros usuários:</strong> Outros usuários podem fornecer informações sobre você ao usarem os
              nossos serviços. Por exemplo, podemos coletar informações a seu respeito a partir de outros usuários se
              eles entrarem em contato conosco para falar sobre você.
            </li>
            <li>
              <strong>Outros parceiros:</strong> Podemos receber informações a seu respeito por parte de nossos
              parceiros, por exemplo, quando anúncios da Umatch são publicados nos sites ou plataformas de parceiros (em
              cujo caso estes podem nos fornecer detalhes sobre o êxito de uma campanha).
            </li>
          </ul>
        </p>
        <strong>Informações coletadas ao usar os nossos serviços</strong>
        <p>
          Quando você usa os nossos serviços, coletamos informações sobre quais recursos usou, como os usou e os
          dispositivos usados para acessar os nossos serviços. Consulte as informações a seguir para obter mais
          detalhes:
          <ul>
            <li>
              <strong>Informações sobre utilização:</strong> Coletamos informações sobre a sua atividade em nossos
              serviços, por exemplo, quando você os usa (por ex., data e hora em que iniciou a sessão, recursos que
              usou, pesquisas, cliques e páginas que lhe foram apresentadas, endereço de páginas Web de referência,
              anúncios nos quais você clica) e como interage com outros usuários (por ex., usuários com os quais se
              conecta e interage, hora e data das interações, número de mensagens que envia e recebe).
            </li>
            <li>
              <strong>Informações sobre o dispositivo:</strong> Coletamos informações de e sobre o(s) dispositivo(s) que
              você usa para acessar os nossos serviços, incluindo:
              <ul>
                <li>
                  informações sobre hardware e software, como endereço IP, identidade e tipo de dispositivo,
                  configurações e características de aplicativos e específicas do dispositivo, erros do aplicativo, ID
                  de publicidade (como AAID do Google e IDFA da Apple, sendo ambos números gerados de forma aleatória
                  que podem ser repostos nas configurações do seu dispositivo), tipo de navegador, versão e idioma,
                  sistema operacional, fusos horários, identificadores associados a cookies ou outras tecnologias que
                  podem identificar de forma única o seu dispositivo ou navegador (por ex., IMEI/UDID e endereço MAC);
                </li>
                <li>
                  informações sobre a sua conexão de rede móvel ou sem fios, como fornecedor de serviço e força do
                  sinal;
                </li>
                <li>informações sobre sensores do dispositivo, tais como acelerômetros, giroscópios e bússolas.</li>
              </ul>
            </li>
            <li>
              <strong>Outras informações com a sua autorização:</strong> Se você nos autorizar, podemos coletar a sua
              geolocalização exata (latitude e longitude) através de várias formas, de acordo com o serviço e o
              dispositivo que você utiliza, incluindo GPS, Bluetooth ou conexões via Wi-Fi. A coleta da sua
              geolocalização pode ocorrer em segundo plano mesmo quando você não está usando os serviços se a
              autorização que nos conceder expressamente permitir essa coleta. Se recusar autorizar a coleta de sua
              geolocalização, não coletaremos esses dados. Do mesmo modo, se você nos autorizar, podemos coletar as suas
              fotografias (por exemplo, se você fizer upload de uma foto em nossos serviços).
            </li>
          </ul>
        </p>
        {/* ================= COMO NÓS UTILIZAMOS OS SEUS DADOS ================== */}
        <h2>3. Como nós utilizamos os seus dados</h2>
        <p>
          O principal motivo pelo qual usamos as suas informações é o de fornecer e melhorar os nossos serviços. Além
          disso, usamos as suas informações para ajudar a manter a sua segurança e para lhe fornecer anúncios que possam
          ser do seu interesse. Continue lendo para obter uma explicação mais detalhada.
        </p>
        <strong>Para gerenciar a sua conta e lhe fornecer os nossos serviços</strong>
        <p>
          <ul>
            <li>Criar e gerenciar a sua conta</li>
            <li>Fornecer-lhe serviço de atendimento ao cliente e responder aos seus pedidos</li>
            <li>Comunicar sobre os nossos serviços, incluindo gestão de pedidos e faturamento</li>
          </ul>
        </p>
        <strong>Para ajudá-lo a criar relações com outros usuários</strong>
        <p>
          <ul>
            <li>
              Analisamos o seu perfil, sua atividade no serviço e suas preferências para recomendar conexões
              significativas e recomendar o seu perfil aos outros.
            </li>
          </ul>
        </p>
        <strong>Para lhe apresentar anúncios e ofertas relevantes</strong>
        <p>
          <ul>
            <li>Gerir sorteios, concursos, descontos e outras ofertas</li>
            <li>
              Desenvolver, apresentar e rastrear conteúdo e anúncios adaptados aos seus interesses em nossos serviços e
              outros sites
            </li>
            <li>
              Comunicar com você por e-mail ou dispositivo móvel sobre produtos ou serviços que possam ser do seu
              interesse
            </li>
          </ul>
        </p>
        <strong>Para melhorar os nossos serviços e desenvolver serviços novos</strong>
        <p>
          <ul>
            <li>Administrar discussões em grupo e pesquisas</li>
            <li>
              Realizar pesquisa e análise do comportamento dos usuários para melhorar os nossos serviços e o nosso
              conteúdo
            </li>
            <li>Desenvolver novos recursos e serviços</li>
          </ul>
        </p>
        <strong>Para impedir, detectar e combater fraude ou outras atividades ilegais e não autorizadas</strong>
        <p>
          <ul>
            <li>Tratar de comportamentos incorretos alegados ou contínuos dentro e fora da plataforma</li>
            <li>
              Realizar análise de dados para melhor compreender e desenvolver medidas de combate a essas atividades
            </li>
            <li>Conservar dados relacionados a atividades fraudulentas para impedir reincidências</li>
          </ul>
        </p>
        <strong>Para assegurar conformidade legal</strong>
        <p>
          <ul>
            <li>Cumprimento de requisitos legais</li>
            <li>Contribuir para o cumprimento da lei</li>
            <li>Aplicação ou implementação de nossos direitos, por exemplo, de nossos Termos</li>
          </ul>
          Para processar as suas informações conforme descrito anteriormente, dependemos das seguintes bases legais:
          <ul>
            <li>
              <strong>Fornecer-lhe o nosso serviço:</strong> na maioria das vezes, o motivo pelo qual processamos as
              suas informações é para executar o contrato que você tem conosco. Por exemplo, à medida que você utiliza o
              nosso serviço para criar relações significativas, utilizamos as suas informações para gerir a sua conta e
              o seu perfil, tornando-os visíveis a outros usuários e também para lhe recomendar outros usuários.
            </li>
            <li>
              <strong>Interesses legítimos:</strong> podemos usar as suas informações nos casos em que temos interesses
              legítimos para o fazer. Por exemplo, analisamos o comportamento de usuários em nossos serviços para
              melhorar de forma contínua a nossa oferta, sugerimos ofertas que consideramos serem do seu interesse, e
              processamos informações para fins administrativos, detecção de fraudes e outros efeitos legais.
            </li>
            <li>
              <strong>Consentimento:</strong> ocasionalmente, podemos pedir o seu consentimento para utilizar as suas
              informações para motivos específicos. Você pode recusar o seu consentimento a qualquer momento entrando em
              contato conosco por meio do endereço fornecido no final desta Política de Privacidade.
            </li>
          </ul>
        </p>
        {/* ================= DO COMPARTILHAMENTO DOS DADOS ================== */}
        <h2>4. Do compartilhamento dos dados</h2>
        <p>
          Apenas alguns funcionários específicos do Serviço de atendimento ao cliente e responsáveis habilitados da
          Umatch processam os seus dados pessoais para os fins descritos acima. Esses funcionários e responsáveis
          habilitados têm acesso apenas aos únicos dados necessários para que realizem suas funções.
          <br />
          <br />
          <strong>Prestadores de serviços e parceiros aprovados e confiáveis</strong> podem, do mesmo modo, ter acesso
          e/ou processar os seus dados pessoais em nome da Umatch em conformidade com o respeito da Política de
          privacidade e garantem a segurança e a privacidade destes. Esses prestadores nos ajudam a operar e aprimorar
          nossos Serviços, principalmente no que se refere:
          <ul>
            <li>à hospedagem dos dados;</li>
            <li>à identificação e prevenção de fraudes e golpes;</li>
            <li>aos estudos, análises e estatísticas;</li>
            <li>à utilização do Aplicativo e/ou funcionamento correto do Aplicativo;</li>
            <li>o fornecimento da assistência necessária a você;</li>
            <li>à gestão de denúncias;</li>
            <li>
              ao marketing e publicidade, com o intuito de enviar ofertas, mensagens e conteúdos pertinentes a você.
            </li>
          </ul>
          Por fim, poderemos transmitir seus dados pessoais mediante solicitação de órgãos estatais, nomeadamente
          autoridades jurídicas, para responder a requisições jurídicas ou legais.
        </p>
        {/* ================= OS SEUS DIREITOS ================== */}
        <h2>5. Os seus direitos</h2>
        <p>
          Você possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais e pelo RGPD:
          <ul>
            <li>
              <strong>Direito de confirmação e acesso:</strong> é o direito do usuário de obter do aplicativo a
              confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse
              o caso, o direito de acessar os seus dados pessoais;
            </li>
            <li>
              <strong>Direito de retificação:</strong> é o direito do usuário de obter do aplicativo, sem demora
              injustificada, a retificação dos dados pessoais inexatos que lhe digam respeito;
            </li>
            <li>
              <strong>Direito à eliminação dos dados (direito ao esquecimento):</strong> é o direito do usuário de ter
              seus dados apagados do aplicativo;
            </li>
            <li>
              <strong>Direito à limitação do tratamento dos dados: </strong> é o direito do usuário de limitar o
              tratamento de seus dados pessoais, podendo obtê-la quando contesta a exatidão dos dados, quando o
              tratamento for ilícito, quando o aplicativo não precisar mais dos dados para as finalidades propostas e
              quando tiver se oposto ao tratamento dos dados e em caso de tratamento de dados desnecessários;
            </li>
            <li>
              <strong>Direito de oposição: </strong> é o direito do usuário de, a qualquer momento, se opor por motivos
              relacionados com a sua situação particular, ao tratamento dos dados pessoais que lhe digam respeito,
              podendo se opor ainda ao uso de seus dados pessoais para a definição de perfil de marketing (profiling);
            </li>
            <li>
              <strong>Direito de portabilidade dos dados: </strong> é o direito do usuário de receber os dados pessoais
              que lhe digam respeito e que tenha fornecido ao aplicativo, num formato estruturado, de uso corrente e de
              leitura automática, e o direito de transmitir esses dados a outros aplicativos;
            </li>
            <li>
              <strong>Direito de não ser submetido a decisões automatizadas: </strong> é o direito do usuário de não
              ficar sujeito a nenhuma decisão tomada exclusivamente com base no tratamento automatizado, incluindo a
              definição de perfis (profiling), que produza efeitos na sua esfera jurídica ou que o afete
              significativamente de forma similar.
            </li>
          </ul>
          Você poderá exercer os seus direitos por meio de comunicação escrita enviada ao aplicativo com o assunto
          “RGPD-”, especificando:
          <ul>
            <li>
              Nome completo ou razão social, número do CPF (Cadastro de Pessoas Físicas, da Receita Federal do Brasil) e
              endereço de e-mail cadastrado na Umatch;
            </li>
            <li>Direito que deseja exercer junto ao aplicativo;</li>
            <li>Data do pedido e sua assinatura;</li>
            <li>Todo documento que possa demonstrar ou justificar o exercício de seu direito.</li>
          </ul>
          O pedido deverá ser enviado ao e-mail: <strong>contato@umatch.com.br</strong>
          .
          <br /> <br />
          Lhe informaremos em caso de retificação ou eliminação dos seus dados.
        </p>
        {/* ================= COOKIES ================== */}
        <h2>6. Dados de navegação (cookies)</h2>
        <p>
          Cookies são pequenos arquivos de po enviados pelo aplicativo ao computador do usuário e que nele ficam
          armazenados, com informações relacionadas à navegação do aplicativo. <br />
          <br />
          Por meio de cookies, pequenas quantidades de informação são armazenadas pelo navegador do usuário para que
          nosso servidor possa lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre o dispositivo
          utilizado pelo usuário, bem como seu local e horário de acesso ao aplicativo.
          <br />
          <br />
          Os cookies não permitem que qualquer arquivo ou informação sejam extraídos do disco rígido do usuário, não
          sendo possível, ainda, que, por meio deles, se tenha acesso a informações pessoais que não tenham partido do
          usuário ou da forma como utiliza os recursos do aplicativo.
          <br />
          <br />É importante ressaltar que nem todo cookie contém informações que permitem a identificação do usuário,
          sendo que determinados tipo de cookies podem ser empregados simplesmente para que o aplicativo seja carregado
          corretamente ou para que suas funcionalidades funcionem do modo esperado.
          <br />
          <br />
          As informações eventualmente armazenadas em cookies que permitam identificar um usuário são consideradas dados
          pessoais. Dessa forma, todas as regras previstas nesta Política de Privacidade também lhes são aplicáveis.
        </p>
        <strong>Cookies do aplicativo</strong>
        <p>
          Os cookies do aplicativo são aqueles enviados ao computador ou dispositivo do usuário e administrador
          exclusivamente pelo aplicativo.
          <br />
          <br />
          As informações coletadas por meio destes cookies são utilizadas para melhorar e personalizar a experiência do
          usuário, sendo que alguns cookies podem, por exemplo, ser utilizados para lembrar as preferências e escolhas
          do usuário, bem como para o oferecimento de conteúdo personalizado.
        </p>
        <strong>Cookies de terceiros</strong>
        <p>
          Alguns de nossos parceiros podem configurar cookies nos dispositivos dos usuários que acessam nosso
          aplicativo.
          <br />
          <br />
          Estes cookies, em geral, visam possibilitar que nossos parceiros possam oferecer seu conteúdo e seus serviços
          ao usuário que acessa nosso aplicativo de forma personalizada, por meio da obtenção de dados de navegação
          extraídos a partir de sua interação com o aplicativo.
          <br />
          <br />
          O usuário poderá obter mais informações sobre os cookies de terceiro e sobre a forma como os dados obtidos a
          partir dele são tratados, além de ter acesso à descrição dos cookies utilizados e de suas características,
          acessando o seguinte link:
          <br />
          <br />
          <strong>Google Analytics:</strong>{' '}
          <a href='https://developers.google.com/analytics/devguides/collection/gajs/cookie-usage'>Clique aqui</a>
          <br /> <br />
          As entidades encarregadas da coleta dos cookies poderão ceder as informações obtidas a terceiros.
        </p>
        <strong>Gestão dos cookies e configurações do navegador</strong>
        <p>
          O usuário poderá se opor ao registro de cookies pelo aplicativo, bastando que desative esta opção no seu
          próprio navegador ou aparelho.
          <br />
          <br />
          A desativação dos cookies, no entanto, pode afetar a disponibilidade de algumas ferramentas e funcionalidades
          do aplicativo, comprometendo seu correto e esperado funcionamento. Outra consequência possível é remoção das
          preferências do usuário que eventualmente tiverem sido salvas, prejudicando sua experiência.
          <br />
          <br />
          A seguir, são disponibilizados alguns links para as páginas de ajuda e suporte dos navegadores mais
          utilizados, que poderão ser acessadas pelo usuário interessado em obter mais informações sobre a gestão de
          cookies em seu navegador:
          <br />
          <br />
          <strong>Internet Explorer:</strong>{' '}
          <a href='https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies'>
            Clique aqui
          </a>
          <br />
          <br />
          <strong>Safari:</strong> <a href='https://support.apple.com/pt-br/guide/safari/sfri11471/mac'>Clique aqui</a>
          <br />
          <br />
          <strong>Google Chrome:</strong>{' '}
          <a href='https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt'>Clique aqui</a>
          <br />
          <br />
          <strong>Mozilla Firefox:</strong>{' '}
          <a href='https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam'>Clique aqui</a>
          <br />
          <br />
          <strong>Opera:</strong> <a href='https://www.opera.com/help/tutorials/security/privacy/'>Clique aqui</a>
        </p>
        {/* ================= PRAZO DE CONSERVAÇÃO DOS DADOS PESSOAIS ================== */}
        <h2>7. Prazo de conservação dos dados pessoais</h2>
        <p>
          Conservamos seus dados durante todo o período necessário para a utilização de sua conta, ou seja:
          <ul>
            <li>
              Durante <strong>o período de inscrição do Membro, ampliado de um ano</strong>. Depois de um ano de
              inatividade em sua conta, esta última será cancelada e seus dados serão conservados durante um ano, antes
              de serem destruídos de maneira definitiva. Sua conta é considerada inativa caso seu aplicativo não tenha
              estabelecido contato com os nossos servidores e o você não tenha utilizado o aplicativo. É preciso lembrar
              que, de acordo com nossa qualidade de fornecedores de hospedagem, possuimos a obrigação jurídica de
              conservar certos dados de caráter pessoal durante um período de um ano, em conformidade com o artigo 6°,
              II da lei de confiança na economia digital de 21 de junho de 2004.
            </li>
            <li>
              Quando o você <strong>exclui sua conta ou solicita sua exclusão</strong> ao serviço de atendimento ao
              cliente, ela é excluída do aplicativo. Não obstante, seus dados serão conservados em um banco de dados
              separado durante um ano antes de serem destruídos de maneira definitiva, com vista a permitir que
              cumpramos com nossa obrigação de conservação na qualidade de fornecedores de hospedagem.
            </li>
            <li>
              Seu endereço de e-mail será conservado <strong>para fins de desenvolvimento de negócios</strong> durante 1
              ano após 1 ano de inatividade da conta. Em caso de exclusão de sua conta, o e-mail não será utilizado para
              fins de desenvolvimento de negócios.
            </li>
            <li>
              <strong>No que se refere às solicitações de exercício de direitos:</strong> durante o período necessário
              para que provemos que concedeu resposta às solicitações em caso de litígio ou de fiscalização: 1 ano a
              partir do mês posterior à sua solicitação.
            </li>
          </ul>
          Nós nos comprometemos a envidar todos os esforços e utilizar os meios dos quais dispomos para garantir a
          segurança máxima dos dados conservados. Contudo, cabe a você tomar as medidas apropriadas para proteger os
          seus dados.
        </p>
        {/* ================= DADOS PESSOAIS DE MENORES DE IDADE ================== */}
        <h2>8. Dados pessoais de menores de idade</h2>
        <p>
          Os nossos serviços estão limitados a usuários de idade igual ou superior a 18 anos. Não permitimos a
          utilização da nossa plataforma por usuários menores de 18 anos e não coletamos de forma consciente informações
          pessoais de pessoas menores de 18 anos. Em caso de falso testemunho por parte de um usuário sobre a sua data
          de nascimento e, mais precisamente, em caso de declaração fraudulenta relativa à sua maioridade, os pais do
          menor de idade são convidados a nos notificar através do email <strong>contato@umatch.com.br</strong>, com o
          intuito de solicitar que os dados sejam apagados. Nos comprometemos a apagar o conjunto de dados pessoais
          referentes ao menor de idade o mais breve possível.
        </p>
        {/* ================= DAS ALTERAÇÕES ================== */}
        <h2>9. Das alterações</h2>
        <p>
          Uma vez que estamos sempre em busca de formas novas e inovadoras de ajudá-lo a criar relações significativas,
          esta política pode mudar ocasionalmente. Enviaremos uma notificação em caso de modificação desta.
        </p>
        {/* ================= CONTATO ================== */}
        <h2>10. Contato</h2>
        <p>
          Em caso de qualquer questão referente à presente política de privacidade ou de qualquer pedido relativo a seus
          dados pessoais, você pode entrar em contato conosco através do email <strong>contato@umatch.com.br</strong>.
        </p>
      </Content>
      <Footer />
    </Container>
  );
};
