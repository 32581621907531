import React from 'react';
import { useState } from 'react';

import logo from '../../assets/logo.svg';
import api from '../../services/api';

import { Container } from './styles';
import { useQuery } from '../../hooks/queryParams';

export const MailUnsubscribe = () => {
  const query = useQuery();

  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const tokenUserIncomplete = query.get('tu');
  const userEmail = query.get('email');

  let pageType;

  if (tokenUserIncomplete && userEmail) pageType = 'tu';
  else pageType = 'invalid';

  if (pageType === 'invalid')
    return (
      <Container>
        <img src={logo} alt='Umatch' />

        <h1>
          Ocorreu um erro{' '}
          <span role='img' aria-label='sad emoji'>
            🙁
          </span>
        </h1>

        <p>Este link é inválido. </p>
      </Container>
    );

  async function handleSubmit() {
    try {
      setLoading(true);

      await api.put('/user_incomplete', {
        email: userEmail,
        token: tokenUserIncomplete,
      });

      setIsSuccess(true);
    } catch (error) {
      setLoading(false);
      alert('Houve um erro ao descadastrar seu e-mail. Tente novamente mais tarde');
    }
  }

  return (
    <Container>
      <img src={logo} alt='Umatch' />

      {isSuccess ? (
        <h1>O seu e-mail foi descadastrado com sucesso.</h1>
      ) : (
        <>
          <h1>Não quer mais receber nossos e-mails? 🥺</h1>

          <p>Vamos ficar com saudades...</p>

          <button disabled={loading} type='submit' onClick={handleSubmit}>
            {loading ? 'Carregando...' : 'Continuar'}
          </button>
        </>
      )}
    </Container>
  );
};
